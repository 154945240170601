import React from "react";
import PropTypes from "prop-types";

export default function BankerHeadshot({ email, width = 90, height = 90, style = {}, "aria-label": ariaLabel }) {
  const emailPrefix = email.split("@")[0].replace(/\./g, "-").toLowerCase();
  const altText = emailPrefix.replace(/\./g, " ");
  const bankerHeadshotName = `${emailPrefix}.jpg`;
  const bankerHeadShotUrl = `/images/banker-headshots/${bankerHeadshotName}`;
  const defaultHeadShotUrl = "/images/banker-headshots/wafd-bank-banker-default.png";

  return (
    <object
      data={bankerHeadShotUrl}
      type="image/jpg"
      width={width}
      height={height}
      style={{ borderRadius: "50%", ...style }}
      aria-label={ariaLabel ? ariaLabel : altText}
    >
      <img
        src={defaultHeadShotUrl}
        alt="WaFd Bank Flower Symbol"
        width={width}
        height={height}
        style={{ borderRadius: "50%", ...style }}
      />
    </object>
  );
}

BankerHeadshot.propTypes = {
  email: PropTypes.string.isRequired,
  "aria-label": PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  style: PropTypes.object
};
