import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import Icon from "../custom-widgets/icon";
import BestBanksDefault from "../best-banks/best-banks-default";
import Button from "../custom-widgets/button";
import BankerHeadshot from "../../components/banker-headshot";

export const query = graphql`
  query branchStateQuery($slug: String) {
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
    }
    idahoIntroImage: file(relativePath: { eq: "cards/articles-cards/idaho-capitol-600.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    nevadaIntroImage: file(relativePath: { eq: "cards/articles-cards/nevada-600.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    newMexicoIntroImage: file(relativePath: { eq: "albuquerque-plaza-wafd-building-600.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    utahIntroImage: file(relativePath: { eq: "cards/articles-cards/utah-commercial-team-600.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    oregonIntroImage: file(relativePath: { eq: "cards/state-commercial/thumbnail-commercial-team-oregon-081823.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    arizonaIntroImage: file(relativePath: { eq: "thumbnail-arizona-commercial-team-730.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    washingtonIntroImage: file(relativePath: { eq: "thumbnail-washington-commercial-team-071823.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    texasIntroImage: file(relativePath: { eq: "cards/articles-cards/thumbnail-texas-commercial-team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    californiaIntroImage: file(relativePath: { eq: "thumbnail-california-022124.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const nameToId = (name) => {
  return name.toLocaleLowerCase().replace(/&+/g, "and").replace(/\s+/g, "-");
};

const IdahoBusinessReviewImg = () => {
  return (
    <div className="row">
      <div className="col-sm-6 col-lg-4">
        <img
          src="/images/wafd-bank-idaho-best-regional-bank-2024.png"
          alt="Idaho Business Review Reader Rankings 2024 Best Regional bank Winner badge."
          className="w-100"
        />
      </div>
    </div>
  );
};

const TeamNavigation = ({ texasNavData, className }) => {
  return (
    <div className={className}>
      <h2 className="text-success">Meet our Texas Commercial Team</h2>
      <ul className="list-unstyled">
        {texasNavData.map(({ name, shortName }, index) => {
          return (
            <li key={index}>
              <Link
                to={`#${nameToId(name)}-team-anchor`}
                id={`${nameToId(name)}-team-anchor-link`}
                className="text-decoration-none"
              >
                <Icon name="arrow-down" class="mr-2 mt-1" />
                <span className="d-none d-md-inline">{name}</span>
                <span className="d-md-none">{shortName || name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const CommercialBanker = ({
  name,
  description,
  emailAddress,
  emailBtnText,
  jobTitle,
  phoneNumber,
  branch,
  address,
  city,
  state,
  postalCode
}) => {
  const id = nameToId(name);
  return (
    <div className={`col-md-6 py-4`} id={id}>
      <div className="row mb-3">
        <div className="col-auto">
          <BankerHeadshot email={emailAddress} aria-label={`WaFd Bank ${jobTitle} - ${name}}`} />
        </div>
        <div className="col-8 col-sm-9 col-md-7 col-lg-9">
          <h5 className="mb-0">{name}</h5>
          <p id={`${id}-job-title`} className="mb-0">
            {jobTitle}
            <br />
            {phoneNumber && (
              <a id={`${id}-phone-number`} href={`tel:${phoneNumber}`} className="text-decoration-none">
                <Icon class="mr-2" name="phone-alt" />
                {phoneNumber}
              </a>
            )}
          </p>
          {branch && (
            <p id={`${id}-branch-data`} className="mb-0">
              {branch}
              <br />
              {address}
              <br />
              {city}, {state} {postalCode}
            </p>
          )}
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: description }} id={`${id}-description`} />
      {emailBtnText && (
        <Button
          containerClass="d-flex"
          id={`${id}-email-cta`}
          class="btn btn-primary col col-sm-auto"
          text={emailBtnText}
          url={`mailto:${emailAddress}`}
          icon={{ position: "right", lib: "fal", name: "envelope-open-text", class: "mt-1 ml-3" }}
        />
      )}
    </div>
  );
};

const CommercialTeam = ({ name, noTitle, link, bankers, texasNavData, shortName }) => {
  return (
    <div className={name ? "pt-4" : ""} id={name ? `${nameToId(name)}-team-anchor` : ""}>
      {name && !noTitle ? (
        <div className="row mx-0 bg-info">
          <div className="container py-1 text-white d-md-flex justify-content-between align-items-center">
            <h3 className="mb-0 d-none d-md-block">{name}</h3>
            <h3 className="mb-1 d-md-none">{shortName || name}</h3>
            {link && (
              <Link id={`${name}-page-link`} className="font-weight-bold text-decoration-none text-white" to={link.url}>
                {link.text}
                <Icon name="arrow-right" class="ml-1" />
              </Link>
            )}
          </div>
        </div>
      ) : null}
      <section className="container pt-0">
        <div className="row">
          {bankers.map((banker, index) => {
            if (banker.navigation)
              return (
                <TeamNavigation texasNavData={texasNavData} className={"col-md-6 d-md-none d-lg-block"} key={index} />
              );
            const id = nameToId(banker.name);
            return <CommercialBanker {...banker} id={id} key={index} />;
          })}
        </div>
      </section>
    </div>
  );
};

const StateCommercialBankingTemplate = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };
  const metaTitle = StateName + " Commercial Banking";

  const metaDescription =
    "WaFd Bank's team of " +
    StateName +
    " Commercial Bankers can help with your real estate project and business success.";

  const ogImagePath = {
    AZ: "thumb-arizona-commercial-team-01-250.jpg",
    NV: "thumb-nevada-commercial-team-01-250.jpg",
    UT: "thumb-utah-commercial-team-01-250.jpg",
    OR: "thumb-oregon-commercial-team-01-250.jpg",
    NM: "thumb-new-mexico-commercial-team-01-250.jpg",
    ID: "thumb-idaho-commercial-team-01-250.jpg",
    TX: "og-texas-commercial-team.jpg",
    WA: "og-commercial-washington-072423.jpg",
    CA: "og-locations-california-02082024.jpg"
  };

  const ogImage = "https://www.wafdbank.com/images/adsearch/states/" + ogImagePath[StateCode];

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/" + stateData.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: ogImage
      }
    ]
  };

  const introductionContent = {
    content: {
      AZ: "Your commercial needs have met their match with our experienced Arizona Commercial Banking team. Averaging almost 30 years of experience per team member, you can find success and get help with real estate projects, business purchases, equipment financing, working capital, and more. On average, our team finances more than $285 million in commercial loans for Arizona clients each year. Knowledgeable in industries from construction to healthcare, give us a call and experience the difference WaFd Bank can make for you.",
      ID: "WaFd Bank's seasoned Idaho Commercial team is ready to help your business reach its goals, and our products and services are customized for your needs. For new or growing businesses, we offer credit cards, small business loan options, and user-friendly online banking. For established companies, we provide purchase cards, customized financing solutions, and robust treasury services. Our Idaho team offers expertise in nearly all industries, including construction, manufacturing, home builders, and health care.  With local decision making and superior customer service, we are ready to help when you need us, always practicing the WaFd Bank core value of simply being nice. With almost 110 years of commitment to industry leading technology, we look forward to continuing to grow with Idaho businesses.",
      NM: "Your deal is in good hands with our seasoned New Mexico Commercial Banking team. Boasting more than 150 years in combined experience, we know what it takes to get your commercial loan booked. Annually, our team closes on average $200 million in commercial loans for New Mexico clients. With unmatched personal service, we can help you with just about any industry, from construction to healthcare. Whatever project you're working on, whether it's business purchase, equipment financing, working capital, real estate projects and more, we've got your back. When you see WaFd Bank's name atop Albuquerque Plaza, consider experiencing the difference WaFd Bank can make for you.",
      NV: "The Nevada Commercial Banking team has over 60 years of combined experience and is well known in our market for its sophistication, flexibility, creativity, and certainty of execution. WaFd Bank focuses on real estate secured, C&I, and equipment loans in addition to construction financing. The Nevada team closes more than $300 million in commercial loans annually for our clients and has a robust and growing pipeline. We cover a wide range of industries with unmatched knowledge and professionalism. Call us to experience the difference WaFd Bank will make for you and your business.",
      UT: "If you have commercial needs, our Utah Commercial Banking team can help. Here in Utah, we strive to live by a phrase coined by our CEO, &ldquo;Love what you do, and make a difference&rdquo;. We provide financing of all sizes for any type of project, including real estate development (such as multi-family and industrial), business acquisition, equipment financing, working capital, and more. Over the years, we are excited to say we have provided over $1 billion in commercial lending for Utah clients. With unmatched personal service and over 200 years of combined experience, our team has the expertise and know how to serve as your trusted partner. We look forward to continuing to help grow businesses right here in the Beehive State.",
      OR: "Here at WaFd Bank, our experienced Oregon Commercial Bankers knows what it takes to get your commercial loan booked, whatever your goals might be. With an impressive combined 250 years of experience, we do real estate projects, business purchases, working capital, equipment financing and more. Every year on average, our team finances more than $300 million in commercial loans for Oregon clients. We have the ability to help your business succeed too, whether you're in agriculture, healthcare, construction, or anywhere in between and beyond.",
      TX: "Our Texas Commercial Banking team has combined experience well over 350 years, so we know a thing or two about helping businesses succeed. Here at WaFd Bank we offer help with real estate projects, working capital needs, equipment finance, business acquisition, treasury services, and more. Each year our team finances an average of over $1 billion in commercial loans for Texas clients. From construction to manufacturing, we have you covered with unmatched service and know-how. Give us a call and experience the difference WaFd Bank can make for you.",
      WA: "If you have commercial needs, our Washington Commercial Banking team can help. We do real estate projects, business purchase, equipment financing, construction, government banking, working capital, and more. On average, our team finances more than $1 billion annually in commercial loans for Washington clients. With unmatched personal service, our team also has the experience and expertise to serve as your trusted partner in a variety of industries from construction to healthcare. We look forward to continuing to help grow businesses right here in the Evergreen State.",
      CA: "Your commercial needs are in good hands with our experienced California Commercial Banking team. With over a century of combined experience on our team, you can find success and get help with real estate projects, business purchases, equipment financing, working capital, and more. Whether you're purchasing or refinancing, we have you covered with unmatched service and know-how. Give us a call and experience the difference WaFd Bank can make for you."
    },
    image: {
      AZ: data.arizonaIntroImage.childImageSharp.gatsbyImageData,
      ID: data.idahoIntroImage.childImageSharp.gatsbyImageData,
      NV: data.nevadaIntroImage.childImageSharp.gatsbyImageData,
      UT: data.utahIntroImage.childImageSharp.gatsbyImageData,
      OR: data.oregonIntroImage.childImageSharp.gatsbyImageData,
      NM: data.newMexicoIntroImage.childImageSharp.gatsbyImageData,
      TX: data.texasIntroImage.childImageSharp.gatsbyImageData,
      WA: data.washingtonIntroImage.childImageSharp.gatsbyImageData,
      CA: data.californiaIntroImage.childImageSharp.gatsbyImageData
    },
    altText: {
      AZ: "Panoramic view of Phoenix, Arizona.",
      ID: "Sunset over Capitol of Idaho, City of Boise skyline.",
      NV: "Panoramic view of Las Vegas Nevada",
      UT: "Panoramic view of Salt Lake City, Utah",
      OR: "Panoramic view of Portland, Oregon",
      NM: "Albuquerque Plaza WaFd Bank Building",
      TX: "Dallas, Texas skyline at dusk.",
      WA: "Seattle, Washington downtown view.",
      CA: "Downtown San Jose, California city skyline."
    }
  };

  const nevadaCommercialBankersData = [
    {
      name: "Anthony Stelluto",
      jobTitle: "Commercial Division Manager",
      phoneNumber: "702-243-4408",
      description:
        "Anthony has over 25 years of commercial lending and finance experience.  He is knowledgeable in all aspects of C&amp;I lending, and investor and owner-occupied real estate lending, including construction finance.  As the Nevada Commercial Division Manager, Anthony oversees a team of seasoned bankers dedicated to providing custom financing solutions for the bank's clients. Anthony holds a Bachelor of Science in Economics from Rutgers University. He has been a local Las Vegan since 2004.",
      emailAddress: "anthony.stelluto@wafd.com",
      emailBtnText: "Email Anthony",
      borderClass: "border-md-bottom"
    },
    {
      name: "Patrick Hubbard",
      jobTitle: "Senior Commercial Relationship Manager ",
      phoneNumber: "702-243-4403",
      description:
        "Patrick has more than 25 years of commercial lending and relationship management experience in the Las Vegas, Nevada market.  His area of expertise is financing commercial real estate throughout Nevada with particular emphasis on owner-occupied properties of all asset classes as well as investor-owned multi-family and industrial properties.  As a third-generation Nevadan, Patrick understands the broader market which enables him to maintain a state-wide focus and support his clients and their opportunities wherever they may be located.",
      emailAddress: "patrick.hubbard@wafd.com",
      emailBtnText: "Email Patrick",
      borderClass: "border-md-bottom"
    },
    {
      name: "Kristina Witty",
      jobTitle: "Senior Credit Analyst",
      phoneNumber: "702-243-4402",
      description:
        "Kristina started in banking in 2017 as a credit analyst in commercial and industrial, progressed to portfolio management, and is now focusing on quantitative analysis and underwriting of commercial real estate loans and related covenants testing. Kristina holds a Bachelor of Science in Management and a Bachelor of Art in Psychology from UNLV and a Master's degree in Management and Leadership from Pepperdine University. She is a local Las Vegan.",
      emailAddress: "kristina.witty@wafd.com",
      emailBtnText: "Email Kristina"
    }
  ];

  const idahoCommercialBankersData = [
    {
      name: "Reid Wiggins",
      jobTitle: "Commercial Division Manager",
      phoneNumber: "208-338-7380",
      borderClass: "border-md-bottom",
      description:
        "With more than 20 years commercial banking experience, Reid has expertise helping finance businesses in nearly all industries.  This includes real estate projects, business purchases, working capital and equipment financing.  As the Idaho Commercial Division Manager, Reid partners with the commercial banking team to provide customized lending and account solutions.",
      expertise: "C&I Banking, CRE Development",
      emailAddress: "reid.wiggins@wafd.com",
      emailBtnText: "Email Reid"
    },
    {
      name: "Bryan Churchill",
      jobTitle: "Senior Relationship Manager",
      phoneNumber: "208-338-7386",
      borderClass: "border-md-bottom",
      description:
        "Bryan is a veteran of the commercial banking industry with over 25 years of lending experience. His primary area of expertise and focus is investor-owned commercial real estate lending, including multi-family housing, office, retail, and industrial projects, as well as homebuilder lines of credit. As a 4th generation Idahoan, Bryan has a passion for all that Idaho has to offer and a desire to see it flourish.",
      expertise: "Commercial Construction, Homebuilder Lines of Credit",
      emailAddress: "bryan.churchill@wafd.com",
      emailBtnText: "Email Bryan"
    },
    {
      name: "Justin Shaffley",
      jobTitle: "Commercial Relationship Manager",
      phoneNumber: "208-338-7395",
      borderClass: "border-md-bottom",
      description:
        "Justin has spent the better part of the last 20 years as a lender, currently focusing on the financing both commercial real estate and homebuilders in the Treasure Valley.  He prides himself on being a trusted advisor to his clients and out of the box thinker, always willing to discuss a project and look for solutions.  When not working, Justin enjoys spending time with his family hunting, fishing, camping, traveling and coaching baseball.",
      expertise: "Commercial Real Estate Financing, Homebuilder Lines of Credit",
      emailAddress: "justin.shaffley@wafd.com",
      emailBtnText: "Email Justin"
    },
    {
      name: "Marcie Jones",
      jobTitle: "Small Business Banker",
      phoneNumber: "208-338-7402",
      description:
        "Marcie is a Boise native who joined WaFd bank in 2015.  Her primary focus is helping small businesses with their lending needs.  She enjoys working with multifamily transactions and helping businesses achieve their CRE goals.  Marcie is an active member of Commercial Real Estate Women (CREW) and enjoys spending time with her family.",
      expertise: "Small Business Lending",
      emailAddress: "Marcie.Jones@wafd.com",
      emailBtnText: "Email Marcie"
    },
    {
      name: "Maria Garcia",
      jobTitle: "Treasury Relationship Manager",
      phoneNumber: "208-365-7363",
      description:
        "Maria focuses on building relationships with businesses to understand and assess their unique needs and provides solutions that streamline cash flow, mitigate risk and fraud, and optimize liquidity. She works closely with the Commercial Relationship Managers to provide clients with a comprehensive team approach to meet every need. Maria has been in the banking industry for 18 years and has spent the past 10 years in Treasury Management.",
      expertise: "Treasury Management Services and Commercial & Municipal Deposits",
      emailAddress: "Maria.Garcia@wafd.com",
      emailBtnText: "Email Maria"
    }
  ];

  const newMexicoCommercialBankersData = [
    {
      name: "Joshua Smith",
      jobTitle: "Commercial Real Estate Division Manager",
      phoneNumber: "505-341-7352",
      emailAddress: "Joshua.Smith@wafd.com",
      emailBtnText: "Email Joshua",
      borderClass: "border-md-bottom",
      description:
        "NM Commercial Division Manager for WaFd Bank, Joshua Smith, enjoys working with a team of exceptionally talented Commercial Bankers across New Mexico. Josh's career began in the Mutual Fund industry but moved to Albuquerque with his wife and child in 2007, beginning his career in Commercial Banking. His primary area of expertise is multi-family housing, investor-owned commercial real estate lending including self-storage and industrial projects and Business Banking.<br/><br/>Josh sits on the Executive Management Committee of the Urban Land Institute (ULI) NM, Board of Directors Veterans Integration Centers and NM Small Business Investment Council. The father of four children, Josh spends his time camping, skiing and traveling with his family."
    },
    {
      name: "Jeannie Miller",
      jobTitle: "Relationship Manager",
      phoneNumber: "505-726-6540",
      description:
        "Jeannie has over 20+ years of experience in commercial and business banking. This includes commercial real estate, multi-family, equipment financing, small business, and treasury products. She strives to assist clients in all aspects of financing in both understanding and coaching for a possible solution. She belongs to the Gallup Rotary Club and Whispering Cedars Water Association. Any spare time is utilized to enjoy nature while, hiking, camping, hunting, fishing, and any DIY projects.",
      emailAddress: "Jeannie.Miller@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Jeannie"
    },
    {
      name: "Aaron Bernabe",
      jobTitle: "Commercial Relationship Manager",
      phoneNumber: "505-341-7351",
      description:
        "With over 15 years of banking experience, Aaron has the knowledge and experience to help his clients succeed financially. While focused on value add and construction projects he also works with his C&I clients to reach their financial goals. As a native New Mexican he has a passion to facilitate growth and improve our local economy. An avid soccer fan, Aaron spends most of his free time participating in the sport and supporting New Mexico United.",
      emailAddress: "Aaron.Bernabe@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Aaron"
    },
    {
      name: "Melissa Gonzales",
      jobTitle: "Senior Depository Officer",
      phoneNumber: "505-341-6850",
      description:
        "Melissa brings over 25-years of commercial banking experience in both the National and Community Banking space, serving as a Commercial Lender, Treasury Management Director and Senior Depository Relationship Manager. Melissa is responsible for maintaining and growing a significant depository portfolio of large Public Fund and Treasury Management Clients and maintains a key leadership role in developing new product strategies while assisting with coaching other Team Members. Melissa is currently serving on the board for the New Mexico Museum of Natural History Museum Foundation and is a past board member of the CNM Foundation.",
      emailAddress: "Melissa.Gonzales@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Melissa"
    },
    {
      name: "Michael Howard",
      jobTitle: "Commercial Relationship Manager",
      phoneNumber: "575-522-2664",
      description:
        "Michael began his banking career in 2016 while completing his MBA at New Mexico State University. Starting as a Teller, he worked his way into commercial banking and now serves as a Commercial Relationship Officer at WaFd bank. His primary focus as a lender is investor-owned commercial real estate financing, including multi-family housing, office, retail, and industrial projects, as well as homebuilder lines of credit. He prides himself on his ability to accurately identify the needs of his clients and works to provide meaningful solutions to meet their financial goals. Michael is a Leadership Las Cruces Alumni and is an active member in the Mesilla Valley Economic Development Alliance and the El Paso Apartment Association.",
      emailAddress: "Michael.Howard@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Michael"
    },
    {
      name: "Crystal Conine",
      jobTitle: "Commercial Senior Relationship Manager",
      phoneNumber: "505-341-7354",
      description:
        "Crystal joined WaFd Bank in August 2016 and has over 20 years commercial banking experience. She is passionate about helping clients grow their businesses and achieve their financial goals. Crystal has a diversified skill set with expertise in commercial real estate, construction financing, C&I business lending, medical and hospitality.",
      emailAddress: "Crystal.Conine@wafd.com",
      emailBtnText: "Email Crystal"
    },
    {
      name: "Jennifer Luciani",
      jobTitle: "Small Business Banker",
      phoneNumber: "505-341-6928",
      description:
        "Jennifer started her banking career in Albuquerque, New Mexico in 2005. Jennifer supports central and northern New Mexico by working with small businesses and volunteering in the community. Jennifer specializes in a variety of lending options. From multi-family and commercial real estate to lines of credit, equipment loans and term loan options. In the community Jennifer works with Junior Achievement, WESST, CREW NM and The Apartment Association of New Mexico. Outside of work, Jennifer is the mother of 2 beautiful children and loves camping with her family.",
      emailAddress: "Jennifer.Luciani@wafd.com",
      emailBtnText: "Email Jennifer"
    }
  ];

  const oregonCommercialTeams = [
    {
      name: "Agribusiness",
      link: {
        text: "Commercial Agribusiness",
        url: "/commercial-banking/specialized-industries/agribusiness-banking"
      },
      bankers: [
        {
          name: "Leigh Ann Evans",
          jobTitle: "VP, Director of Agribusiness Lending",
          phoneNumber: "541-947-3142",
          description:
            "Leigh Ann Evans joined the WaFd team in November 2012 and is currently the Director of the WaFd Bank Agribusiness Lending Group. Coming from a banking background, Leigh Ann has experience ranging from retail branch management, rural community facilities financing and building a commercial loan portfolio with an emphasis on agricultural lending. With a lending focus in the livestock, hay and row crop segments of agricultural lending, she brings with her a broad background of practical experience from the cattle industry. Throughout her 24 years in banking, Leigh Ann has served in a variety of leadership positions within the community and continues to be involved in several local service groups.  Leigh Ann earned a Bachelor's degree from Oregon State University in Agricultural Economics and she and her family live and ranch in Lakeview, Oregon.",
          emailAddress: "LeighAnn.Evans@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Leigh"
        },
        {
          name: "Thomas White",
          jobTitle: "Senior Credit Analyst",
          phoneNumber: "541-774-1452",
          description:
            "Thomas White has been with WaFd Bank for 7 years and is currently the Senior Credit Analyst for the Agribusiness Banking team. His primary lending focus is cattle, hay and grain production, with experience in vineyards and timber. As a 3rd generation rancher, he understands the production flow of agriculture and draws on that knowledge while working with clients.",
          emailAddress: "Thomas.White@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Thomas"
        }
      ]
    },
    {
      name: "Bend Office",
      shortName: "Bend Office",
      bankers: [
        {
          name: "Ryan Brown",
          jobTitle: "Senior Relationship Manager",
          phoneNumber: "541-312-7132",
          description:
            "Ryan has spent the majority of his 16-year banking career serving businesses, people, and communities throughout Central Oregon. A relentless advocate, he takes to heart the importance of supporting his client's entire relationship and in growing the right way. Ryan's in-depth experience of Commercial lending is wide-ranging and includes real estate, construction, equipment, and working capital lines of credit, as well as other financing capabilities. Outside of work, he prefers spending time outdoors with his wife and two daughters.",
          emailAddress: "ryan.brown@wafd.com",
          emailBtnText: "Email Ryan"
        },
        {
          name: "Craig Chenoweth",
          jobTitle: "Commercial Relationship Manager",
          phoneNumber: "541-280-5683",
          description:
            "Craig Chenoweth's professional background provides a diverse range of skills, making him a unique asset in the banking industry. In addition to his years as a Commercial Relationship Manager, specializing in construction and CRE lending since 2016, Craig worked for the City of Bend for 17 years in Planning and Development. There, he facilitated the entitlement process for various commercial, industrial, and residential projects. His consulting work within the private sector as a project manager for development projects provides a detailed understanding of project elements related to permitting and construction. <br/><br/> In addition to his professional roles, Craig has been actively involved in his community. He served on the Bend Chamber of Commerce Board of Directors in 2024 and was a Bend Parks and Recreation District Board Member from 2013 to 2016. Craig has been proud to call Central Oregon his home for more than 25 years and is excited to be part of shaping its future.",
          emailAddress: "craig.chenoweth@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Craig"
        },
        {
          name: "Steve Smith",
          jobTitle: "Community Business Banker",
          phoneNumber: "541-880-5206",
          description:
            "A Klamath Falls native, Steve started his banking career in 2004 as a mortgage loan officer, transitioning into commercial banking in 2013.  Steve enjoys working with local business owners supporting their lending and deposit needs. In his spare time, he enjoys family time, hunting, fishing and watching his girls play soccer. ",
          emailAddress: "Steve.Smith@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Steve"
        },
        {
          name: "Danny Proudfoot",
          jobTitle: "Commercial Division Manager",
          phoneNumber: "541-312-7140",
          description:
            "With over than 20 years banking experience, Danny has expertise in helping finance businesses in nearly all industries. His current focus is Home Builder Finance and Multifamily. As the Central Oregon Commercial Division Manager, he partners with the commercial banking team to provide customized lending and account solutions. Danny is a native Oregonian, living for the past 32 years in Central Oregon. When not working Danny enjoys spending time outdoors and traveling with his wife and son",
          emailAddress: "Danny.Proudfoot@wafd.com",
          emailBtnText: "Email Danny"
        },
        {
          name: "Dan LaCoste",
          jobTitle: "Regional President",
          phoneNumber: "541-434-3270",
          description:
            "Dan has been in banking for 25 years and is well versed in most aspects of the financial industry. Dan's expertise in helping businesses spans from managing your cash to purchasing your dream business to ensuring you have the right amount of working capital. As the Southern Oregon Division Manager, Dan has a team of 5 lenders that are truly the in best in the business. As a resident of Oregon for the past 45 years, he is passionate about helping our state grow and flourish!",
          emailAddress: "Dan.LaCoste@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Dan"
        }
      ]
    },
    {
      name: "Eugene Office",
      bankers: [
        {
          name: "Scott Kuvaas",
          jobTitle: "Relationship Manager",
          phoneNumber: "541-434-3274",
          description:
            "Scott is a native Oregonian with over five years of commercial banking experience, supporting customers throughout the Willamette Valley. His areas of expertise include investor-owned commercial real estate in multi-family housing, office, industrial, retail, and hospitality, as well as commercial construction. Scott is committed to supporting businesses of all sizes to achieve their goals through a supportive banking relationship.  ",
          emailAddress: "Scott.Kuvaas@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Scott"
        },
        {
          name: "Jeannie Sterry",
          jobTitle: "Treasury Relationship Manager",
          phoneNumber: "541-434-3295",
          description:
            "Jeannie Sterry has worked in banking for 30+ years, joining WaFd in 2019. Her focus is around the secure and efficient management of client payables and receivables, combined with fraud tools and a team of support, which provide an effective and robust treasury environment for businesses to thrive.  When Jeannie is not working, she enjoys camping and traveling with family, and volunteering through several local nonprofits.",
          emailAddress: "Jeannie.Sterry@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Jeannie"
        },
        {
          name: "Nicole Nelson",
          jobTitle: "Commercial Division Manager",
          phoneNumber: "541-686-2134",
          description:
            "Nicole has devoted her twenty-year career to commercial banking and serving clients throughout the Willamette Valley. Well versed in various types of commercial lending, Nicole has expertise in commercial real estate, construction, operating lines of credit and equipment finance. Growing up in a family business has given her a deep understanding of business operations and respect for her commercial clients.",
          emailAddress: "Nicole.Nelson@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Nicole"
        }
      ]
    },
    {
      name: "Medford Office",
      bankers: [
        {
          name: "Jerry Herbold",
          jobTitle: "Senior Relationship Manager",
          phoneNumber: "541-774-1457",
          description:
            "Jerry has over 27 years in commercial lending experience in the Southern Oregon area, with the last 9 years at WaFd Bank as a Relationship Manager. His experience includes construction financing for real estate projects including multi-family, commercial and medical facilities. Additionally, he has helped facilitate financing for businesses, including equipment financing, working capital and operating lines of credit. Jerry values building his relationships with his clients and providing customized solutions to meet their lending needs. ",
          emailAddress: "Jerry.Herbold@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Jerry"
        },
        {
          name: "Laura Robles",
          jobTitle: "Commercial Relationship Manager",
          phoneNumber: "503-956-9074",
          description:
            "Laura, a lifelong PNW resident with a decade of diverse banking experience, is dedicated to supporting clients in the Southern Oregon area. Commercial lending specialties include multi-family housing, office, retail, and construction. Through detailed relationship reviews, Laura strives to add customized efficiencies to business operations with the support of her local team. Her priority is full immersion into the communities she lives in through volunteering and exploring all that Southern Oregon has to offer. In her personal life Laura supports the growth of her entrepreneurial husband and two children.",
          emailAddress: "laura.robles@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Laura"
        }
      ]
    },
    {
      name: "Portland Office",
      bankers: [
        {
          name: "Gary Haines",
          jobTitle: "Regional President",
          phoneNumber: "971-300-3313",
          description:
            "Gary joined the bank in 2008 and has over 30 years of experience in the banking industry. He started his banking career in Florida as a Small Business Banker and relocated with his family to the PNW in 2000, where his career path moved him into commercial banking. After joining WaFd, Gary held roles as a commercial relationship manager, senior commercial relationship manager, and division manager.  In 2013, he was asked to relocate to the Portland area to take on a new role with the bank integrating South Valley Bank and Trust into the WaFd family, and ultimately as its Northern Oregon regional president. Gary has been involved in many youth and community organizations, and has done a significant amount of work as a volunteer in the non-profit space in Oregon. He is the past president of JDRF Columbia-Willamette, and a former board member at the United Way Columbia-Willamette. <br/><br/> Gary has been happily married for 30+ years to his wife, Kirsten, and has 4 beautiful children. In his free time, he enjoys watching his children play sports, watching football, and fishing.",
          emailAddress: "Gary.haines@wafd.com",
          emailBtnText: "Email Gary"
        },
        {
          name: "Kent Ikeda",
          jobTitle: "Senior Vice President & Division Manager ",
          phoneNumber: "503-893-0345",
          description:
            "A native of Honolulu, HI, Kent has been a dedicated banking professional since 2005. Throughout his career, he has gained extensive experience working with various national, regional, and community banks. Kent began his journey as a Small Business Banker in Portland, OR, and subsequently held roles in Student Loans and Business Payroll Sales before transitioning to Business and Commercial Banking.<br/><br/>In 2013, Kent relocated to Denver, CO, to join a high-performing Business Banking team. Two years later, he and his wife decided to return to Portland to be closer to family. Upon their return in 2015, Kent joined a regional bank in Business Banking, and by 2018, he had moved into Commercial Banking with a community bank. In 2022, Kent advanced to Middle Market Banking, covering Oregon, Washington, and California.<br/><br/>Kent has been happily married to his wife, Josie, for over 14 years, and they have two children, Hiro and Mira, along with three fur children. An active community member, Kent serves on the boards of The Legacy Meridian Park Foundation, Schoolhouse Supplies, and Mary's Woods.",
          emailAddress: "kent.ikeda@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Kent"
        },
        {
          name: "Daniel Weldon",
          jobTitle: "Vice President & Director of Commercial Banking",
          phoneNumber: "503-327-3985",
          description:
            "Daniel began his 35-year banking career in community development lending in Portland, Oregon. He is well versed in identifying and crafting solutions which meet the needs of commercial investors and developers, with specific expertise in multi-family and construction lending.<br/><br/>Daniel serves on the Board of the Portland Metro chapter of RMA as the Academic and Education committee chair.  He is a graduate of Cal Poly State University in San Luis Obispo and earned his MBA from George Fox University, Newberg.  A 40-year resident of Portland, and the father of two grown and independent sons, he never tires of the wealth of activities here in the Pacific NW.",
          emailAddress: "Daniel.weldon@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Dan"
        },
        {
          name: "Andrea Hyvonen",
          jobTitle: "Vice President & Commercial Relationship Manager",
          phoneNumber: "503-226-1300",
          description:
            "Andrea Hyvonen joins WaFd Bank as Relationship Manager, VP. With nearly 30 years in finance and real estate experience, Andrea is an insightful leader with a constant drive for development and contribution.  She is dedicated, professional, and passionate in delivering high-touch tailored service to her business clients with excelled delivery in multi-family, owner and investor real estate, and C&I lending.",
          emailAddress: "andrea.hyvonen@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Andrea"
        },
        {
          name: "Derek Ditora",
          jobTitle: "Officer & Relationship Manager",
          phoneNumber: "916-833-7359",
          description:
            "Derek has over four years of Commercial Banking experience, joining the bank in 2020 as a Commercial Credit Analyst and working his way to a promotion to Community Business Banker, and recently to Commercial Relationship Manager. Prior to starting his career in banking, Derek worked in public accounting after graduating from Oregon State University with a degree in Finance and Accounting.<br/><br/>Actively involved in the community in volunteer roles, in his free time Derek enjoys being in the outdoors skiing, fishing, or golfing.",
          emailAddress: "derek.ditora@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Derek"
        },
        {
          name: "Madi Jones",
          jobTitle: "Treasury Relationship Manager",
          phoneNumber: "503-306-2737",
          description:
            "Madi Jones is an experienced banking professional, she joined the WaFd team in 2018. Originally from Arizona, Madi relocated to Oregon in 2015, where she discovered her passion for Treasury Management. In 2024, she embraced a new challenge by stepping into the Treasury Relationship Management role, further advancing her expertise in the field. Throughout her career, Madi has prioritized building strong relationships with clients, and focused on providing exceptional service.<br/><br/>Outside of her professional life, Madi is a dedicated pet owner, caring for two fur children and a variety of reptiles. Her personal interests include photography and PC gaming, where she enjoys exploring her creative side and connecting with the gaming community.  With a commitment to excellence in both her career and personal pursuits, Madi continues to grow and make an impact in Commercial Banking and Treasury Management.",
          emailAddress: "madi.jones@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Madi"
        },
        {
          name: "Michelle Christner",
          jobTitle: "Officer & Commercial Portfolio Manager",
          phoneNumber: "503-306-2739",
          description:
            "Michelle brings over 20 years of experience in the banking industry, with a strong background in both retail and commercial banking. Throughout her career, she has specialized in managing commercial portfolios, with a particular focus on Commercial Real Estate. Michelle thrives on building lasting relationships with clients, providing tailored solutions to help them navigate and resolve the complexities of their portfolios.<br/><br/>Michelle is a 5th-generation Oregonian and earned both her Bachelor's and Master's degrees from Oregon State University.  Outside of work, she enjoys spending quality time with her friends and family, including her husband, daughter, and their Australian Shepherd.",
          emailAddress: "michelle.christner@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Michelle"
        },
        {
          name: "Andre Canessa",
          jobTitle: "Officer & Senior Credit Analyst",
          phoneNumber: "503-306-2752",
          description:
            "Andre has over a decade of experience in commercial banking, with a focus on structuring and underwriting commercial real estate construction and term loans. Andre takes pride in guiding WaFd clients through every stage of the lending process and serving as a trusted resource during and after loan origination.<br/><br/>Outside of work, Andre enjoys exploring the outdoors, traveling, and spending time with family and friends.",
          emailAddress: "andre.canessa@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Andre"
        },
        {
          name: "Rita Hsueh",
          jobTitle: "Officer & Senior Credit Analyst",
          phoneNumber: "971-462-3160",
          description:
            "A native of Taipei, Taiwan, Rita has over 10 years experience in the banking industry specialized in credit analysis and risk control. In 2018, Rita immigrated to Portland, Oregon with her family and has continued her career as a credit analyst through joining a local, community bank.  Rita joined WaFd Bank as a Senior Credit Analyst in 2021, supporting our Portland Commercial Banking Team with a focus on C & I lending. As a credit analyst, she has worked with relationship managers in several industries ranging from small business to middle market businesses, both domestically and internationally.<br/><br/>Rita has been happily married to her husband, Vince, for 10 years, and they have raised two children together, Kate and Marcus. Outside of work, Rita likes to spend time traveling and enjoys trying all different kinds of food.",
          emailAddress: "rita.hsueh@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Rita"
        }
      ]
    }
  ];

  const utahCommercialBankersData = [
    {
      name: "Kade Walton",
      jobTitle: "VP, Relationship Manager",
      phoneNumber: "801-440-4411",
      description:
        "With nearly 17 years experience, Kade has a robust working knowledge of the banking and finance industry having held positions in retail, small business, and private banking much of which was spent in management. Kade's primary area of expertise is in Professional Practice lending, High Net Worth lending, investment fund lending, and owner occupied real estate lending. Kade feels one of his greatest strengths is being an outside the box thinker in finding lending solutions for his clients. Kade prides himself in being a skillful handyman and is a University of Utah alumni. When he isn't working, he enjoys spending time with his family, completing DYI projects, rock crawling, and attending sporting events.",
      expertise: "",
      emailAddress: "Kade.Walton@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Kade"
    },
    {
      name: "Dane Margetts",
      jobTitle: "VP, Commercial Division Manager",
      phoneNumber: "801-366-2275",
      description:
        "Dane is a dedicated banking professional with substantial experience in expanding clientele relationships and finding credit and cash management solutions that fit the unique needs of each customer/business. He has built his career on providing detailed information and resources to individuals in an effort to stimulate growth, both financially and professionally. Dane has strived to demonstrate resourcefulness, consciousness, and an ability to solve problems in order to meet the needs of his clients for over 17 years.  He is a proud graduate of the University of Utah (BA in accounting) and Westminster College (MBA). Away from work, Dane spends as much time with his family as possible.",
      expertise: "",
      emailAddress: "Dane.Margetts@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Dane"
    },
    {
      name: "Rhett Rampton",
      jobTitle: "Treasury Relationship Manager",
      phoneNumber: "801-366-2238",
      description:
        "Rhett helps businesses and organizations leverage technology to streamline cash flow, conducting deep dives into clients' business objectives and models to improve their ability to monitor, manage, and protect their financial transactions. With over 15 years of treasury management experience, Rhett enjoys taking the wide range of treasury services WaFd Bank offers and tailoring these to meet the specific needs of individual companies. In addition, he keeps clients apprised of the latest technological innovations and enhancements treasury services offer. A veteran Army helicopter pilot, Rhett enjoys spending time with his family. He received his BA and MBA from Utah State University.",
      expertise: "",
      emailAddress: "Rhett.Rampton@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Rhett"
    },
    {
      name: "Ryan Hoffman",
      jobTitle: "Community Business Banker",
      phoneNumber: "801-366-2259",
      description:
        "Ryan has enjoyed being in the banking industry for over 25 years with experience in both personal and business banking. In that time, he has come to know the value of relationship banking, striving to build relationships of trust, and can be relied upon to do the right thing for each client. Ryan enjoys learning about what makes a particular business tick and what drives their success.  As a relationship officer, Ryan strives to tailor banking services to meet the client's needs and takes a consultative approach in finding solutions. With a Finance degree from the University of Utah, Ryan volunteers his time with a non-profit that helps with affordable housing. When not working, he loves to spend time with his kids and also loves to take advantage of all that Utah has to offer in the outdoors with skiing, snowboarding, mountain biking, hiking, and Jeeping.",
      expertise: "",
      emailAddress: "Ryan.Hoffman@wafd.com",
      emailBtnText: "Email Ryan"
    },
    {
      name: "John Herzog",
      jobTitle: "Senior CRE Relationship Manager",
      phoneNumber: "920-988-1462",
      description:
        "With over 15 years in commercial real estate, John's expertise includes lending, portfolio management, credit training, and team leadership. He's closed over $2 billion in CRE deals nationwide, focusing on multi-family, industrial, mixed-use, retail, office, and medical office properties. His deep understanding of underwriting and credit ensures client needs are not just met but exceeded with value-driven solutions.  John is a relationship builder, placing emphasis on establishing trust and mutual respect with clients through meaningful connections. Outside of work, John cherishes family time, engage in hunting, hiking, fitness, and exploring the world through travel.",
      expertise: "",
      emailAddress: "John.Herzog@wafd.com",
      emailBtnText: "Email John"
    }
  ];

  const arizonaCommercialBankersData = [
    {
      name: "Tim Ponzio",
      jobTitle: "Vice President, Relationship Manager",
      phoneNumber: "480-443-7815",
      description:
        "With 25 years of banking experience and 15 years in commercial banking, Tim has the knowledge to help your business succeed.  Specializing in equipment finance, he partners with and consults the commercial team. In addition to equipment finance, Tim provides deposit account solutions and commercial real estate lending including industrial, multi-family, office, and retail properties.",
      emailAddress: "Tim.Ponzio@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Tim"
    },
    {
      name: "Jim Wardle",
      jobTitle: "Senior Relationship Manager",
      phoneNumber: "480-390-1851",
      description:
        "Jim has more than 30 years of experience in commercial banking, specifically servicing and lending to customers in the technology, medical, legal, commercial, and industrial, and investor real estate segments; while providing comprehensive depository, treasury management, and working capital solutions to customers throughout Arizona. Jim has a successful background in relationship management and leadership and prides himself on building lasting business relationships through mutual trust and a genuine concern for the success of his clients. Jim received separate degrees in Business Administration and Marketing from the University of Arizona. Arizona has been his home for more than 47 years and Jim is excited to be a contributor to the continued growth of the Valley community through both business and philanthropy.",
      emailAddress: "Jim.Wardle@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Jim"
    },
    {
      name: "Steve Ponzo",
      jobTitle: "Senior Relationship Manager",
      phoneNumber: "520-232-5325",
      description:
        "Steve has been a commercial banker in Southern Arizona for over 17 years. He has worked in community, regional, and national bank environments and was mentored by some of the most respected commercial bankers in Southern Arizona. Steve's experience includes credit analysis, loan origination, lending department management, and loan approval. Currently, Steve works alongside his colleagues to build out the Southern Arizona Commercial Banking Office for WaFd Bank Arizona. He also originates commercial loans and provides treasury management solutions to commercial clients across the Southern Arizona region. In his spare time Steve enjoys a wide variety of outdoor activities and spending time with his wife and four children.",
      emailAddress: "Steven.Ponzo@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Steve"
    },
    {
      name: "Jill Malick",
      jobTitle: "Senior Relationship Manager",
      phoneNumber: "520-232-5324",
      description:
        "With more than 29 years of industry experience, 20 in Tucson, Jill is among the region's top specialists in commercial lending, treasury management and portfolio management. Working across all industries, she customizes lending solutions for commercial customers including real estate, working capital lines of credit and equipment. Collaborating with businesses in Tucson, Nogales and Yuma, Jill delivers a superior commercial banking experience. She also has a passion for her local community, having previously served on a number of board of directors including the United Way of Tucson, Southern Arizona, and Tucson Metro Chamber of Commerce. She is an honors graduate of the Pacific Coast Banking School.",
      emailAddress: "Jill.Malick@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Jill"
    },
    {
      name: "Rey J Robles",
      jobTitle: "Community Business Banker",
      phoneNumber: "520-232-5332",
      description:
        "A native of Tucson, Rey has been in banking since 1997. He is an experienced Commercial Banker who has demonstrated a history of leadership and excellence in the banking industry. Rey is skilled in accounting, commercial lending, commercial banking services, sales management and residential lending. After receiving a bachelor's degree in both Accounting and Finance from the University of Arizona, Rey went on to receive his Master's in Business and Administration from the University of Phoenix.<br/><br/>Rey maintains an active involvement in several community service organizations.  He is a member of the Tucson Conquistadores and a past member of both Pima County Community Land Trust and the Arts Foundation for Tucson and Southern Arizona.",
      emailAddress: "Rey.Robles@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Rey"
    },
    {
      name: "Mitch Langston",
      jobTitle: "Banking Officer",
      phoneNumber: "480-443-7827",
      description:
        "Mitch has been in Small Business Banking for over 20 years. His primary area of expertise is owner occupied commercial real estate lending. He also focuses on commercial lines of credit, multi-family housing, and treasury management. Living in the Phoenix area for over 18 years, Mitch enjoys working with small businesses all over the Valley.",
      emailAddress: "Mitch.Langston@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Mitch"
    },
    {
      name: "Jim McCann",
      jobTitle: "Commercial Division Manager",
      phoneNumber: "480-510-0938",
      description:
        "Jim has more than 20 years of experience in banking, with expertise in commercial, industrial and real estate acquisition, refinance, rehab/renovation, construction, cap ex/equipment, working capital, receivables, payables, treasury management, and investment.  Overseeing the Arizona Commercial Division and Equipment Finance teams, Jim has 9 lenders who are truly the best in class. He prides himself as a key business partner skilled in collaborating with senior leadership and cross-functional teams to provide financial recommendations for strategic business decisions.",
      emailAddress: "Jim.McCann@wafd.com",
      emailBtnText: "Email Jim"
    },
    {
      name: "John Bowler",
      jobTitle: "Senior Relationship Manager",
      phoneNumber: "480-209-7907",
      description:
        "After over 20 years in banking, John continues to enjoy old and new friendships he has developed with customers through the years. He is currently focused on the banking needs of companies in the manufacturing, distribution, wholesalers, real-estate, and professional sectors. An Arizona native, he spends most of his free time raising his two sons together with his wife. He is an avid sports and outdoorsman, and also enjoys watching sports and coaching his boys' soccer teams in his free time.",
      emailAddress: "John.Bowler@wafd.com",
      emailBtnText: "Email John"
    }
  ];

  const texasCommercialTeams = [
    {
      noTitle: true,
      bankers: [
        {
          navigation: true
        },
        {
          name: "Tony Barnard",
          jobTitle: "Regional President Texas",
          phoneNumber: "972-419-4162",
          description:
            "Tony is a seasoned banking professional with more than 30 years of banking and lending achievements specializing in regional management and commercial lending. With a proven track record of high-level production contributions and in-depth relationship management, Tony leads WaFd's Texas region in commercial real estate, commercial and industrial, energy, mortgage, and commercial lending as well as all facets of retail banking.",
          emailAddress: "tony.barnard@wafd.com",
          emailBtnText: "Email Tony"
        }
      ]
    },
    {
      name: "Commercial Real Estate Team",
      link: {
        text: "Commercial Real Estate",
        url: "/commercial-banking/commercial-real-estate-loans"
      },
      bankers: [
        {
          name: "Pete Thompson",
          jobTitle: "Division Manager, Commercial Banking",
          phoneNumber: "972-419-4163",
          description:
            "A seasoned banking professional, Pete has experience with credit, production, derivative and management experience, and more. His career in banking and financial services extends over 22 years in the Dallas and Fort Worth Metroplex. Tasked with the management and growth of WaFd's Texas Commercial Real Estate portfolio, Pete and his team have a focus on income producing properties, including multi-family, industrial, office, retail, and medical projects. He takes a consultative approach with clients to address their needs and helps to navigate credit markets so that he can learn how best to guide them. Raised in the Dallas area, Pete still resides nearby with his family. Outside of work he enjoys spending time with his family, traveling, attending sporting events, and supporting the Dallas Mavericks and his TCU Horned Frogs.",
          emailAddress: "pete.thompson@wafd.com",
          emailBtnText: "Email Pete"
        },

        {
          name: "Rocky Rainbolt",
          jobTitle: "CRE Relationship Manager",
          phoneNumber: "214-497-4867",
          description:
            "Rocky has more than 35 years of banking experience, working on all types of commercial real estate transactions through several real estate cycles. Born and raised in North Texas, Rocky has experience in most Texas markets, focusing primarily on investor-owned commercial projects from multi family, industrial and self-storage to retail and office. When not working, Rocky enjoys fishing, golfing, and restoring classic cars.",
          emailAddress: "rocky.rainbolt@wafd.com",
          emailBtnText: "Email Rocky"
        },
        {
          name: "Jeff Payne",
          jobTitle: "Vice President, Relationship Manager",
          phoneNumber: "214-695-6201",
          description:
            "A seasoned commercial real estate lender, Jeff has over 25 years of lending experience in the Houston market. His primary focus is investor-owned commercial real estate lending, including multi-family, retail, office, and industrial projects, as well as single tenant NNN properties. As a native Houstonian, Jeff has experienced Houston's tremendous growth and diversification over the last 25 years and is excited to see it continue as a significant target for commercial real estate investment. Jeff enjoys spending time with his family, golfing, hunting, and fishing.",
          emailAddress: "jeff.payne@wafd.com",
          emailBtnText: "Email Jeff"
        },
        {
          name: "Will Fisher",
          jobTitle: "Assistant Vice President, Portfolio Manager",
          phoneNumber: "972-419-4177",
          description:
            "Will has worked in commercial banking since 2008 with a focus on financing and managing commercial real estate lending relationships.  Transaction experience is concentrated in multifamily, industrial and office. He works closely with WaFd clients through every step of loan origination and serves as an ongoing resource to ensure all needs are met. When not working, Will enjoys running and the outdoors, traveling and spending time with his family.",
          emailAddress: "will.fisher@wafd.com",
          emailBtnText: "Email Will"
        },
        {
          name: "Wendy Valentine",
          jobTitle: "Commercial Relationship Manager",
          phoneNumber: "737-207-3492",
          branch: "Austin Branch",
          address: "3800 N Lamar, Suite 135",
          city: "Austin",
          state: "Texas",
          postalCode: "78756",
          description:
            "Wendy has been in the banking industry for 30 years, with 22 years in commercial lending. Her primary focus is building and maintaining productive relationships with new and existing clients. Outside of work she enjoys travel, outdoor activities, family, and the wonderful ever evolving Austin live music scene. Wendy's motto is &ldquo;If you do what you love, you will never work a day in your life.&rdquo;",
          emailAddress: "wendy.valentine@wafd.com",
          emailBtnText: "Email Wendy"
        },
        {
          name: "Jeremiah Bush",
          jobTitle: "Director of Commercial Banking",
          phoneNumber: "512-213-5572",
          branch: "Austin Branch",
          address: "3800 N Lamar, Suite 135",
          city: "Austin",
          state: "Texas",
          postalCode: "78756",
          description:
            "Jeremiah Bush has over 20 years of experience in the banking industry, serving in leadership roles such as Director of Commercial Banking, Business Banking Manager, and Director of Business Banking. Now at WaFd Bank, Jeremiah leads the bank's efforts in the Austin market as Director of Commercial Banking. A proven leader in both the banking sector and the Austin community, Jeremiah excels at managing relationships with banking partners to deliver seamless, integrated experiences. He provides strategic solutions for growth strategies, treasury solutions, and financial operations. Jeremiah also serves on various charitable boards, demonstrating his commitment to community service.",
          emailAddress: "jeremiah.bush@wafd.com",
          emailBtnText: "Email Jeremiah"
        }
      ]
    },
    {
      name: "Commercial & Industrial Team",
      shortName: "C&I Team",
      link: {
        text: "Commercial Lending",
        url: "/commercial-banking/commercial-lending"
      },
      bankers: [
        {
          name: "Pete Thompson",
          jobTitle: "Division Manager, Commercial Banking",
          phoneNumber: "972-419-4163",
          description:
            "A seasoned banking professional, Pete has experience with credit, production, derivative and management experience, and more. His career in banking and financial services extends over 22 years in the Dallas and Fort Worth Metroplex. Tasked with the management and growth of WaFd's Texas Commercial Real Estate portfolio, Pete and his team have a focus on income producing properties, including multi-family, industrial, office, retail, and medical projects. He takes a consultative approach with clients to address their needs and helps to navigate credit markets so that he can learn how best to guide them. Raised in the Dallas area, Pete still resides nearby with his family. Outside of work he enjoys spending time with his family, traveling, attending sporting events, and supporting the Dallas Mavericks and his TCU Horned Frogs.",
          emailAddress: "pete.thompson@wafd.com",
          emailBtnText: "Email Pete"
        },
        {
          name: "Shannon Bettis",
          jobTitle: "Senior Commercial Relationship Manager",
          phoneNumber: "972-419-4176",
          description:
            "Shannon is a graduate of Mississippi State University and middle market commercial banking executive, specializing in leadership and the design of turnkey financial solutions throughout the Dallas and Fort Worth Metroplex. With over 26 years of experience, Shannon's specialties include commercial and industrial relationships for manufacturers, distributors, wholesalers, professional service companies, and family offices. Her expertise ranges from revolving lines of credit, guidance lines, term loans for equipment, debt consolidation and recapitalization, all the way to owner-occupied real estate. Outside of banking, she is passionate about championing people. Shannon also enjoys HIIT workouts, kickboxing, running, sketching, and all things dog related.",
          emailAddress: "shannon.bettis@wafd.com",
          emailBtnText: "Email Shannon"
        },
        {
          name: "Michael Park",
          jobTitle: "SVP, Senior Relationship Manager",
          phoneNumber: "972-419-4164",
          description:
            "Mike is part of the WaFd C&I Lending team in Dallas. His responsibilities include new business development, growth strategy execution, and relationship management. Previous experience includes Managing Director for CFO Partners, Relationship Manager, Business Development Manager, and a team lead role.<br/><br/>Previous banking experience includes roles as team lead for a Technology & Life Sciences industry lending group, Middle Market loan officer and credit administration officer. In these roles he developed senior debt term sheets for a range of credit facilities and directed the related credit underwriting and financial statement analysis.  Mike has built a strong network and has a proven track record of exceeding business development goals in addition to managing client, referral, and investor relationships.",
          emailAddress: "michael.park@wafd.com",
          emailBtnText: "Email Michael"
        }
      ]
    },
    {
      name: "Homebuilder Finance Team",
      link: {
        text: "Commercial Real Estate",
        url: "/commercial-banking/commercial-real-estate-loans"
      },
      bankers: [
        {
          name: "Brice Bolen",
          jobTitle: "Division Manager, Homebuilder Finance",
          phoneNumber: "972-419-4165",
          description:
            "Brice is a veteran of the commercial banking industry with over 25 years of lending experience. His primary area of expertise and focus is homebuilder finance, including homebuilder lines of credit and acquisition and development financing. Brice is a Baylor university graduate and enjoys travelling and spending time with his family when away from work.",
          emailAddress: "brice.bolen@wafd.com",
          emailBtnText: "Email Brice"
        },
        {
          name: "Jeremy Price",
          jobTitle: "VP Relationship Manager",
          phoneNumber: "972-419-4177",
          description:
            "Working in the banking industry since 2009, Jeremy brings experience in retail, credit management, commercial real estate, and commercial and industrial lending. His focus today is commercial real estate construction, permanent and homebuilder finance, as well as homebuilder lines of credit and acquisition and development financing. Jeremy was born and raised in Dallas Fort Worth and graduated with a finance degree from the University of Nevada, Las Vegas. Outside of work, Jeremy is an avid golfer who enjoys spending time with his wife and son travelling and enjoying all sports, outdoor events, and the arts.",
          emailAddress: "jeremy.price@wafd.com",
          emailBtnText: "Email Jeremy"
        }
      ]
    },
    {
      name: "Energy Team",
      link: {
        text: "Commercial Lending",
        url: "/commercial-banking/commercial-lending"
      },
      bankers: [
        {
          name: "Rusty Stehr",
          jobTitle: "Division Manager, Energy Team",
          phoneNumber: "972-419-4169",
          description:
            "Rusty founded the Energy Lending Team for WaFd Bank in the fall of 2016 and has more than 20 years of energy lending expertise. Graduating with a degree in Business Management from the University of Oklahoma, he also graduated with a Juris Doctor degree from the University of Oklahoma School of Law. In his free time, he loves spending time with his wife of over 20 years and their two children. He also serves on various boards in his free time and is a coach for little league sports through the Arlington YMCA, among other volunteering activities.",
          emailAddress: "rusty.stehr@wafd.com",
          emailBtnText: "Email Rusty"
        },
        {
          name: "Tyler Evans",
          jobTitle: "Assistant Vice President, Energy Team",
          phoneNumber: "972-419-4184",
          description:
            "Tyler has been in the energy banking industry for more than 9 years. Prior to joining WaFd Bank, Tyler spent five years at another financial institution where he helped to facilitate debt financing for exploration, development and production oil and gas companies. He earned his degree in finance with a minor in accounting from Oklahoma State University.",
          emailAddress: "tyler.evans@wafd.com",
          emailBtnText: "Email Tyler"
        },
        {
          name: "Larry Sears",
          jobTitle: "Senior Vice President, Relationship Manager",
          phoneNumber: "972-419-4189",
          description:
            "With a broad level of experience within the energy industry and capital markets, Larry also has more than 40 years of banking experience, providing financing to energy, commercial and industrial, real estate, and agriculture businesses, with both asset based and project financing platforms. Larry has experience originating both domestic and cross-border transactions. Larry's breadth of experience includes originating and managing transactions in the energy sector which included companies engaged in upstream, midstream, downstream refining and petrochemical process industry sectors. He earned his degree from Kansas State University with an emphasis in finance, and his master's in graduate studies at Oklahoma State University. A member of the industry association of ADAM Energy Forum, Larry also currently serves on the Finance Advisory Board for the Department of Finance at Kansas State University.",
          emailAddress: "larry.sears@wafd.com",
          emailBtnText: "Email Larry"
        },
        {
          name: "Chad Rowan",
          jobTitle: "Energy Lending Officer",
          phoneNumber: "972-419-4174",
          description:
            "Chad joined WaFd Bank's Energy Team in 2017 after attending TCU's Energy MBA program in Fort Worth, TX.  His career began in 2008 with a drilling contractor and oil producer in the East Texas area where he learned the business. Cycles over the last 15 years have provided Chad with not only a humble appreciation for the volatility, risks, and rewards of the industry, but also the importance of relationships built on trust. He has served as a WINGS financial coach and is a board member with the East Texas ADAM Energy Forum in Tyler, TX. Chad resides in North Texas with his wife and two children.",
          emailAddress: "chad.rowan@wafd.com",
          emailBtnText: "Email Chad"
        }
      ]
    },
    {
      name: "Treasury Management",
      link: {
        text: "Treasury Management",
        url: "/commercial-banking/treasury-management-services"
      },
      bankers: [
        {
          name: "Martha Dunn",
          jobTitle: "Director of Treasury",
          phoneNumber: "972-419-4168",
          description:
            "Boasting more than 30 years in the banking industry, Martha has a broad range of experience including lending, portfolio restructure, customer migration, product, and treasury management sales. Her focus is partnering with clients to find the right solution to assist with accounting automation, fraud prevention, and working capital management. The WaFd Treasury Team works closely with relationship managers to build a comprehensive understanding of each client's needs. Martha lives by the mantra, &ldquo;great things in business are never done by one person; they are done by a team of people.&ldquo;",
          emailAddress: "martha.dunn@wafd.com",
          emailBtnText: "Email Martha"
        },
        {
          name: "Stephanie Krauss",
          jobTitle: "Treasury Relationship Manager",
          phoneNumber: "972-419-4185",
          description:
            "With more than 20 years of sales experience, Stephanie has been in the banking industry for over 4 years. Her focus is partnering with clients to find the right solution to assist with accounting automation, fraud prevention, and working capital management. Stephanie currently serves on the Board for the North Dallas Chamber of Commerce and is the Chair of the Ambassador Committee for the NDCC, as well as serving on the Board for Girls on the Run DFW. When not working, she enjoys crafting and monogramming gifts for friends.",
          emailAddress: "stephanie.krauss@wafd.com",
          borderClass: "border-md-bottom",
          emailBtnText: "Email Stephanie"
        },
        {
          name: "Shelli Vercell",
          jobTitle: "Treasury Relationship Manager",
          phoneNumber: "972-419-4199",
          description:
            "Shelli has enjoyed a career of over 20 years in the Senior Living industry as an Executive Director of multiple communities. Ranging from independent living, assisted living, and memory care, Shelli says it was a true joy to serve our senior population while learning many life lessons along the way. She joins WaFd to continue helping and serving our communities.<br /><br />A Texas girl through and through, Shellie has spent all but one year of her life living right here in Texas. Growing up in Abilene, she also attended Navarro College there before moving around Dallas and traveling. Shelli is an avid reader and movie watcher, and although she lives on the lake is not much of an angler. Her family means the world to her, and she spends as much time as possible with her children, grandchildren, and granddogs.",
          emailAddress: "shelli.vercell@wafd.com",
          emailBtnText: "Email Shelli"
        }
      ]
    }
  ];

  const washingtonCommercialBankersData = [
    {
      name: "Eric Seidenberger",
      jobTitle: "Commercial Real Estate, Division Manager",
      phoneNumber: "206-626-8534",
      description:
        "Eric is responsible for WaFd's Commercial Real Estate Division throughout Washington, which includes income property such as office, industrial, multifamily, and retail, as well as homebuilder finance including horizontal land development, vertical construction of &ldquo;for sale&rdquo; housing. A 2005 graduate of Angelo State University Texas, Eric is also a Past President of the Risk Management Association's Puget Sound Chapter and the current Chairperson for Washington Mortgage Bankers Association's Income Property Group Committee.<br/><br/>Eric spends his weekends working on endless projects at his ranch on Vashon Island. Further, he and his wife both enjoy discovering new parts of the Pacific Northwest on weekends and travelling internationally for vacations.",
      emailAddress: "Eric.Seidenberger@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Eric"
    },
    {
      name: "Corey Limbaugh",
      jobTitle: "Commercial Banking Division Manager",
      phoneNumber: "206-356-4783",
      description:
        "Corey Limbaugh is the Division Manager leading WaFd's Washington Middle Market Commercial Banking team. The team partners with businesses with annual revenues generally in the $20 - $500 million range to deliver customized treasury and credit solutions.<br/><br/>Prior to joining WaFd in 2021 Limbaugh spent 20 years working for other banks in their Commercial Banking divisions. Corey is a 1993 graduate of Pacific Lutheran University, receiving his MBA at the University of Phoenix and completing Pacific Coast Banking School in 2010. Limbaugh is active on the Board of the West Seattle Food Bank and volunteers regularly for Food Lifeline. He is a 20+ year resident of West Seattle where he lives with his wife, Ann, and two high schoolers.",
      emailAddress: "Corey.Limbaugh@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Corey"
    },
    {
      name: "Tim Marymee",
      jobTitle: "Vice President-Manager<br/>Community Multi-Family Loan Group",
      phoneNumber: "206-777-8213",
      description:
        "Tim has been active in real estate finance for more than 35 years, and since 2003 has focused on commercial banking in the Puget Sound region. He currently manages the Community Multi-Family Lending (CMFL) team at WaFd, which specializes in financing multi-family properties, self-storage facilities, and manufactured home parks.<br/><br/>Tim enjoys working with local rental housing providers supporting their lending and deposit needs. He is a former board member of the Washington Mortgage Bankers Association (WMBA) and is currently a member of the WMBA Income Property Group Committee. Additionally, Tim is active in the Rental Housing Association of Washington.<br/><br/>In his free time Tim enjoys camping trips with his wife in their travel trailer and visiting with family in California.",
      emailAddress: "Tim.Marymee@wafd.com",
      emailBtnText: "Email Tim"
    },
    {
      name: "Pete Sullivan",
      jobTitle: "Director of Treasury Services - Northern Washington",
      phoneNumber: "206-626-8111",
      description:
        "Pete is responsible for overseeing all treasury services, products, procedures, and operations for Northern Washington, including leading the middle market treasury team. The team is responsible for the continuous improvement of treasury onboardings and implementations to ensure an exceptional client experience. The team also provides technical treasury product and services expertise, including Online Banking, Remote Deposit, ACH, Wires, Purchase Cards, Positive Pay, and Account Analysis.<br/><br/>Pete has over 25 years of experience in banking. In 1997, Pete started at Bank of America as a teller, then joined the commercial team in 2003, serving as an analyst and then Relationship Manager. In 2015, Pete joined WaFd Bank to help build our treasury and government banking presence.<br/><br/>Pete is a graduate of Wayne State College. He is an avid reader of history, collects trading cards, and always has a yard project, which his two boys studiously avoid. Married now for nearly 20 years, Pete and his family live on Bainbridge Island.",
      emailAddress: "Pete.Sullivan@wafd.com",
      emailBtnText: "Email Pete"
    }
  ];

  const californiaCommercialBankersData = [
    {
      name: "Alyson Buttery",
      jobTitle: "SVP, Commercial Division Manager",
      phoneNumber: "206-204-3413",
      description:
        "Alyson leads the Commercial Division of California, specializing in investor real estate, including retail, industrial, office, and multifamily. Following graduation from California Polytechnic State University with a degree in Psychology, she immediately entered the banking industry, working for community and commercial banks for the last 20 years. She is a Pacific Coast Banking School graduate, has dedicated years to local community mental health organizations and has been a key driver of Diversity & Inclusion initiatives at local, national, and international levels of organizations. Alyson resides on the Central Coast of California with her two active sons.",
      emailAddress: "Alyson.Buttery@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Alyson"
    },
    {
      name: "Mason Rosenthal",
      jobTitle: "Senior Commercial Relationship Manager",
      phoneNumber: "559-284-8941",
      description:
        "As a relationship manager for WaFd's Commercial Real Estate Division, Mason is responsible for new business development through commercial loan originations, such as office, industrial, retail, and multifamily property types, including horizontal land development and vertical construction financing. Additionally, Mason manages existing customer relations, including portfolio management. </br></br> Immediately after graduating from CSU Fresno, Mason began his Commercial Real Estate Finance career in 2010 as a correspondent Life Insurance company lender with Fresno-based firm Capitalize. In 2023, Mason joined WaFd Bank to help facilitate our expansion in the California Region. </br></br> Mason was born and raised in the Central Valley of California. He enjoys fishing and traveling with his wife and four kids in their travel trailer.",
      emailAddress: "Mason.Rosenthal@wafd.com",
      borderClass: "border-md-bottom",
      emailBtnText: "Email Mason"
    },
    {
      name: "Eric Boucher",
      jobTitle: "Commercial Relationship Manager",
      phoneNumber: "310-909-3149",
      description:
        "As a relationship manager for WaFd's Commercial Real Estate Division, Eric is responsible for new business development through commercial loan originations, such as office, industrial, retail, and multifamily property types, including horizontal land development and vertical construction financing. Additionally, Eric manages existing customer relations, including portfolio management. </br></br> Eric began his career in Commercial Real Estate Finance in 2006 as a commercial real estate appraiser. In 2017, he joined HFF in the Century City office on the west side of Los Angeles (acquired by JLL in 2020), closing over $2 billion in debt, equity, and investment sales transactions across 300+ deals nationwide, focusing on southern California. In 2023, Eric joined WaFd Bank to help facilitate expansion into the California market and build out the CRE portfolio. </br></br> Eric grew up in southern California and enjoys time with his three teenage kids, spearfishing, hiking, and watching them play water polo and dance classical ballet.",
      emailAddress: "Eric.Boucher@wafd.com",
      emailBtnText: "Email Eric"
    },
    {
      name: "James Arom",
      jobTitle: "VP, Senior Commercial Relationship Manager",
      phoneNumber: "818-720-3620",
      description:
        "As a relationship manager for WaFd's Commercial Real Estate Division, James is responsible for new business development through commercial loan originations, such as office, industrial, retail, and multifamily property types, including horizontal land development and vertical construction financing. Additionally, James is able to offer owner occupied financing and SBA 504 products through WaFd's commercial and industrial platform.</br></br> Beginning his career as a business banker in 2004, James soon became a commercial real estate lender supporting investor clients as well as clients needing owner occupied business financing for both real estate and business expansion needs.</br></br>James graduated from California State University Los Angeles in Industrial Technology, emphasizing in automation and robotics. He is an active member of the community and serves as a board member on a nonprofit organization for the past 12 years in support of disabled children and adults. He has 2 grown kids and enjoys swimming, skiing, and hiking.",
      emailAddress: "James.Arom@wafd.com",
      emailBtnText: "Email James"
    }
  ];

  function getStateBankersData() {
    switch (StateCode) {
      case "AZ":
        return arizonaCommercialBankersData;
      case "NM":
        return newMexicoCommercialBankersData;
      case "UT":
        return utahCommercialBankersData;
      case "ID":
        return idahoCommercialBankersData;
      case "NV":
        return nevadaCommercialBankersData;
      case "WA":
        return washingtonCommercialBankersData;
      case "CA":
        return californiaCommercialBankersData;
      default:
        return null;
    }
  }
  const isTexas = StateCode === "TX";
  const isIdaho = StateCode === "ID";
  const texasNavData = texasCommercialTeams.reduce((acc, { name, shortName }) => {
    if (name) acc.push({ name, shortName });
    return acc;
  }, []);

  const isOregon = StateCode === "OR";
  return (
    <SecondaryLanding footerBorder={false}>
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />
      <section className="container pb-0">
        <div className="row">
          <div className="col-md-6 order-1 order-md-0">
            <div className="d-none d-md-block mb-3">
              <GatsbyImage
                id="default-image-logo"
                image={introductionContent.image[StateCode]}
                alt={introductionContent.altText[StateCode]}
              />
              {isTexas && <TeamNavigation className="d-none d-md-block d-lg-none" texasNavData={texasNavData} />}
            </div>
            {StateCode === "OR" && (
              <>
                <h2 className="text-success">Meet our Oregon Commercial Team</h2>
                <ul className="list-unstyled no-gutters row row-cols-1 row-cols-sm-2">
                  {oregonCommercialTeams.map((data, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`#${nameToId(data.name)}-team-anchor`}
                          id={`${nameToId(data.name)}-team-anchor-link`}
                          className="text-decoration-none"
                        >
                          <Icon name="arrow-down" class="mr-2 mt-1" />
                          <span className="d-none d-md-inline">{data.name}</span>
                          <span className="d-md-none">{data.name}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
          <div className="col-md-6">
            <h1>{StateName} Commercial Banking</h1>
            <h5
              className="font-weight-normal"
              dangerouslySetInnerHTML={{ __html: introductionContent.content[StateCode] }}
            />
          </div>
        </div>
        {isIdaho && <IdahoBusinessReviewImg />}
      </section>
      {isTexas ? (
        <section className="p-0">
          {texasCommercialTeams.map((team, index) => {
            return <CommercialTeam {...team} texasNavData={texasNavData} key={index} />;
          })}
        </section>
      ) : isOregon ? (
        <section className="p-0">
          {oregonCommercialTeams.map((team, index) => {
            return <CommercialTeam {...team} key={index} />;
          })}
        </section>
      ) : (
        <section className="container">
          <h2 className="text-success">Meet our {StateName} Commercial Team</h2>
          <div className="row">
            {getStateBankersData().map((banker, index) => {
              const id = banker.name.toLocaleLowerCase().replace(/\s+/g, "-");
              return (
                <div className={`col-md-6 py-4 ${banker.borderClass}`} id={id} key={index}>
                  <div className="row mb-3">
                    <div className="col-auto">
                      <BankerHeadshot email={banker.emailAddress} />
                    </div>
                    <div className="col-8 col-sm-9 col-md-7 col-lg-9">
                      <h5 className="mb-0">{banker.name}</h5>
                      <div id={`${id}-job-title`} dangerouslySetInnerHTML={{ __html: banker.jobTitle }} />
                      {banker.phoneNumber && (
                        <a
                          id={`${id}-phone-number`}
                          href={`tel:${banker.phoneNumber}`}
                          className="text-decoration-none"
                        >
                          <Icon class="mr-2" name="phone-alt" />
                          {banker.phoneNumber}
                        </a>
                      )}
                    </div>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: banker.description }} id={`${id}-description`} />
                  {banker.expertise && <p className="font-weight-bold text-success">Expertise: {banker.expertise}</p>}
                  {banker.emailBtnText && (
                    <Button
                      containerClass="d-flex"
                      id={`${id}-email-cta`}
                      class="btn btn-primary col-12 col-sm-5 col-md-8 col-lg-6 col-xl-5 no-min-width"
                      text={banker.emailBtnText}
                      url={`mailto:${banker.emailAddress}`}
                      icon={{ position: "right", lib: "fal", name: "envelope-open-text", class: "mt-1 ml-3" }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </section>
      )}

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

StateCommercialBankingTemplate.propTypes = {
  branch: PropTypes.object
};

export default StateCommercialBankingTemplate;

